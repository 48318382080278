import 'gmaps';
import GMaps from 'gmaps';

document.addEventListener('turbolinks:load', function(){
  var mapElement = document.querySelector("#map")
  if (!mapElement) {
    return
  }

  var gmap = new GMaps({
    el: '#map',
    lat: 37.87738989205349,
    lng: 127.71264858106396,
    zoom: 13,
    styles: [{
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }]
    }],
    click: function(e) { setTimeout(1000, () => { gmap.removeOverlays() }); }
  });
  window.gmap = gmap;

  var dataset = mapElement.dataset
  var urban_parks = JSON.parse(dataset.urbanParks);
  window.urban_parks = urban_parks;

  urban_parks.forEach(function(m){
    gmap.addMarker({
      lat: m.latitude,
      lng: m.longitude,
      title: `${m.name}`,
      click: function(tree) {
        if (gmap.overlays.length) {
          gmap.removeOverlays();
        };
        gmap.setCenter(m.latitude, m.longitude);
        gmap.drawOverlay({
          lat: m.latitude,
          lng: m.longitude,
          content: `
          <div class="w-72 flex flex-col">
            <div class="flex bg-green-600 px-8 py-4 justify-between md:text-base text-sm text-white">
              <div>${m.name}</div>
              <a href='javascript:gmap.removeOverlays();'><i class="fas fa-times"></i></a>
            </div>
            <div class="flex flex-col bg-white px-8 py-4">
              <div class="text-base justify-between flex">
                <div>No. ${m.id}</div>
                <div class="md:text-base text-sm rounded border-solid border-2 border-gray-300 rounded text-gray-600 bg-gray-100 hover:bg-gray-300 p-1"><a href="/observations/new?urban_park_id=${m.id}">생물정보 올리기</a></div>
              </div>
              <div class="md:text-base text-sm">면적: <b>${m.area ? m.area + ' m<sup>2</sup>' : '(조사중)'}</b></div>
              <div class="md:text-base text-sm">생물종 수: <b>${m.count ? m.count : '(조사중)'}</b></div>
              <div class="flex my-2">
                <img class="w-full" onerror="this.src='/sample-park-forest.png';" src='${ m.picture_drive_url ? m.picture_drive_url : '/sample-park-forest.png' }'
                style="min-height:140px">
              </div>
              <div class="text-center md:text-base text-sm rounded border-solid border-2 border-gray-300 text-gray-600 bg-gray-100 hover:bg-gray-300 p-1 mb-1"><a href="/urban_parks/${m.id}">공원정보 보기</a></div>
              <div class="text-center md:text-base text-sm rounded border-solid border-2 border-gray-300 text-gray-600 bg-gray-100 hover:bg-gray-300 p-1"><a href="/urban_parks/${m.id}/observations">생물정보 보기</a></div>
            </div>
          </div>`,
          click: function() {},
        });
      },
    });
  });
})