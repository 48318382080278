// Script For Close alert
document.addEventListener('turbolinks:load', function() {
  var alert_del = document.querySelectorAll('.alert-del');
  alert_del.forEach((x) =>
    x.addEventListener('click', function () {
      x.parentElement.classList.add('hidden');
     })
  );
  setTimeout(()=>{
    var n = document.querySelector('#flash-notice');
    var a = document.querySelector('#flash-alert');
    if (n) { n.classList.add('opacity-0'); }
    if (n) { n.classList.add('hidden'); }
    if (a) { a.classList.add('opacity-0'); }
    if (a) { a.classList.add('hidden'); }
  },4000);
})