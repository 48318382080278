document.addEventListener('turbolinks:load', function() {
  if (document.querySelector('#refresh')) {
    document.querySelector('#refresh').addEventListener('click', function () {
      gmap.setCenter(37.87738989205349, 127.71264858106396);
      gmap.setZoom(13);
    })
  }

  // 카카오 공유
  if ( !Kakao.isInitialized() ) {
    Kakao.init(document.querySelector('meta[name="kakao_api_key"]').getAttribute('content'))
  }
  if (document.querySelector('#share-kakao-pc')) {
    console.log("kakao-share-pc");
    console.log(document.querySelector('meta[name="original_url"]').getAttribute('content'));
    Kakao.Link.createScrapButton({
      container: '#share-kakao-pc',
      requestUrl: document.querySelector('meta[name="original_url"]').getAttribute('content'),
    })  
  }
  if (document.querySelector('#share-kakao-mobile')) {
    console.log("kakao-share-mobile");
    console.log(document.querySelector('meta[name="original_url"]').getAttribute('content'));
    Kakao.Link.createScrapButton({
      container: '#share-kakao-mobile',
      requestUrl: document.querySelector('meta[name="original_url"]').getAttribute('content'),
    })  
  }
})